import { SetupContext, computed } from 'vue';
import { useStore } from 'vuex';

/** Interfaces */
import {
  AgreementsI,
  LastPaymentStatusI,
  LinceObligationI,
  NegotiationsI,
  PortfolioI,
  RepositoryObligationI,
} from '@/interfaces/general.interface';

export function usePaymentCard({ emit }: SetupContext): any {
  const store = useStore();

  const bindClassState = (state: string) => {
    return {
      activo: ['activo', 'activa', 'al dia', 'aprobada'].some(
        (item) => state.toLowerCase() === item
      ),
      enMora: ['en mora', 'cancelado'].some(
        (item) => state.toLowerCase() === item
      ),
      proximoAvencer: state.toLowerCase() === 'proximo a vencer',
    };
  };

  const hiddenPartialData = (data: string): string => {
    return `${'*'.repeat(7)}${data?.substr(-4)}`;
  };

  const viewDetailPlan = (
    agreement: AgreementsI | NegotiationsI | LinceObligationI,
    obligationType: string
  ) => {
    emit('view-detail-plan', { agreement, obligationType });
  };

  const makePayment = (
    agreement: AgreementsI | NegotiationsI | LinceObligationI
  ) => {
    emit('make-payment', agreement);
  };

  const hasBussiness = (
    agreement: PortfolioI | AgreementsI | NegotiationsI
  ) => {
    return agreement.business.length > 0;
  };

  const valueGreaterThanZero = (value: number): boolean => {
    return value > 0;
  };

  const getReference = (
    obligation:
      | AgreementsI
      | NegotiationsI
      | LinceObligationI
      | RepositoryObligationI
  ): string => {
    return obligation.payment_information[0].reference;
  };

  const getLastPaymentStatus = (reference: string): string => {
    const dataLastPaymentStatus = computed<LastPaymentStatusI[]>(
      () => store.state.dataLastPaymentStatus
    );

    const status = dataLastPaymentStatus.value.find(
      (item) => item.reference === reference
    );

    return status ? status.status : '———';
  };

  const canMakePayment = (reference: string): boolean => {
    return getLastPaymentStatus(reference).toLocaleLowerCase() !== 'pendiente';
  };

  return {
    bindClassState,
    hiddenPartialData,
    viewDetailPlan,
    makePayment,
    hasBussiness,
    getReference,
    canMakePayment,
    getLastPaymentStatus,
    valueGreaterThanZero,
  };
}
