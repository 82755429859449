
import { computed, defineComponent, inject, PropType, SetupContext } from 'vue';
import { useStore } from 'vuex';
import { ApmBase } from '@elastic/apm-rum';

/** Interfaces */
import {
  ObligationWithoutAgreementParamsI,
  PortfolioI,
  ResponseDownloadPdfI,
} from '@/interfaces/general.interface';

/** Composables */
import { usePaymentCard } from '@/composition/usePaymentCard';
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

/** Utils */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import {
  base64ToFile,
  disableEncryption,
  showErrors,
  redirectWhatsappAdviser,
} from '@/utils/general-utils';

/** Services */
import { RequestDocumentService } from '@/services/request-document.service';
import { EncryptService } from '@/services/encrypt.service';

export default defineComponent({
  name: 'ObligationCard',
  props: {
    obligationsByPortfolio: {
      type: Array as PropType<PortfolioI[]>,
      required: true,
    },
  },
  emits: ['negotiation'],
  setup(_props, context: SetupContext) {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, addTransaction, endTransactionApm } =
      useElasticTransactionApm();

    const statePaymentPlan = () => {
      return 'Activo';
    };

    const clientIsInsolvent = computed<boolean>(() => {
      return Boolean(store.state?.dataGeneral?.is_insolvent);
    });

    const negotiation = (portfolio: PortfolioI) => {
      if (portfolio?.judicialized_portfolio || clientIsInsolvent.value) {
        redirectWhatsappAdviser(
          process.env.VUE_APP_WHATSAPP_GESTOR,
          'Necesito más opciones para pagar mi obligación'
        );
      } else {
        context.emit('negotiation', portfolio);
      }
    };

    const getDataCertification = (portfolio: PortfolioI) => {
      const data: ObligationWithoutAgreementParamsI = {
        bank_name: portfolio.bank_name,
        cesion_date: portfolio.cesion_date,
        business: [...portfolio.business].map((item) => {
          return {
            id: +item.business_id,
            credit: item.credit,
            product: item.product,
            capital_value: item.capital_value,
            interests: item.interests,
            current_debt_value: item.current_debt_value,
          };
        }),
        type_id: portfolio.type_id,
      };

      return data;
    };

    const generateCertificate = (portfolio: PortfolioI) => {
      const dataCertificate = getDataCertification(portfolio);
      customTransactioncApm(
        $apm,
        'click - generar-certificado',
        'custom',
        dataCertificate
      );
      showSwalLoading();
      new RequestDocumentService()
        .downloadPdf(dataCertificate)
        .then((response) => {
          closeSwal();
          const { data } = response;
          const res: ResponseDownloadPdfI = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const { success, message, attributes } = res.data;
          addTransaction(res.data);
          if (success) {
            const file: File = base64ToFile(
              attributes,
              `Certificado-${portfolio.portfolio_id}.pdf`,
              'application/pdf'
            );
            const newBlob = new Blob([file], {
              type: 'application/pdf',
            });
            const windowUrl = window.URL || window.webkitURL;
            const url = windowUrl.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Certificado-${portfolio.portfolio_id}.pdf`
            );
            link.click();
            setTimeout(() => {
              windowUrl.revokeObjectURL(url);
            }, 1000);
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al generar el certificado, por favor intentelo de nuevo.'
          );
        })
        .finally(() => endTransactionApm());
    };

    const { hiddenPartialData, hasBussiness, bindClassState } =
      usePaymentCard(context);

    return {
      statePaymentPlan,
      hiddenPartialData,
      getDataCertification,
      generateCertificate,
      negotiation,
      hasBussiness,
      bindClassState,
    };
  },
});
